<template>
  <span
    v-if="filterCount"
    class="absolute -top-0.5 right-1 z-50 flex aspect-square min-h-[1.25rem] min-w-[1.25rem] items-center justify-center rounded-full bg-red p-1 text-xs text-white shadow-md"
    :aria-label="`${filterCount} ${$t(
      `search-form.item${filterCount > 1 ? 's' : ''}-in-filter`
    )}`"
  >
    {{ filterCount }}
  </span>
</template>

<script setup lang="ts">
import { useSearchStore } from '@autobid/nuxt-pinia-store/store/searchStore/useSearchStore'
import { storeToRefs } from 'pinia'

const { chips } = storeToRefs(useSearchStore())

const filterCount = computed(() => {
  return chips.value.reduce((acc, curr) => {
    const currentCount = Array.isArray(curr.value) ? curr.value.length : 1
    return (acc += currentCount)
  }, 0)
})
</script>
